import {Collapse} from "bootstrap";

var collapseElementList = [].slice.call(document.querySelectorAll(".collapse"));
var collapseList = collapseElementList.map(function (collapseEl) {
  return new Collapse(collapseEl);
});

var hour = document.querySelector('.watch-hands #hour');
var minute = document.querySelector(".watch-hands #minute");
var second = document.querySelector(".watch-hands #second");

window.requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  function (f) {
    setTimeout(f, 60);
  };

function updateclock() {
  var curdate = new Date();
  var hour_as_degree =
    ((curdate.getHours() + curdate.getMinutes() / 60) / 12) * 360;
  var minute_as_degree = ((curdate.getMinutes() + curdate.getSeconds() / 60) / 60 ) * 360;
  var second_as_degree =
    ((curdate.getSeconds() + curdate.getMilliseconds() / 1000) / 60) * 360;

  hour.setAttribute("transform", "rotate(" + hour_as_degree + " 600 1440)");
  minute.setAttribute("transform", "rotate(" + minute_as_degree + " 600 1440)");
  second.setAttribute("transform", "rotate(" + second_as_degree + " 600 1440)");

  requestAnimationFrame(updateclock);
}

requestAnimationFrame(updateclock);